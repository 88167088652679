body{
	color:#ffffff;
	background:#000000;
	margin:0
}

.next-error-h1{
	border-right:1px solid rgba(0,0,0,.3);
	display: inline-block;
	margin: 0 20px 0 0;
	padding-right:23px;
	font-size:24px;
	font-weight:500;
	vertical-align:top;
}

.next-error-h2 {
	font-size:14px;
	font-weight:400;
	line-height:28px;
}

@media (prefers-color-scheme:dark){
	.next-error-h1{
		border-right:1px solid rgba(255,255,255,.3);
	}
}

.next-error-Div1 {
	color: #ffffff;
	font-family:system-ui, Segoe UI;
	display: flex;
	justify-content: center;
	min-height: 100vh;
}


.next-error-Div2 {
	line-height:48px;
}

.next-error-Div3 {
	display: inline-block;
}